import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M4000 7925 l0 -35 1400 0 1400 0 0 35 0 35 -1400 0 -1400 0 0 -35z"/>
<path d="M4240 7695 l0 -35 105 0 105 0 -2 -1232 -3 -1233 -102 -3 -103 -3 0
-34 0 -35 255 0 255 0 0 35 0 35 -100 0 -100 0 0 1168 0 1167 20 -30 c11 -16
20 -33 20 -36 0 -3 22 -46 48 -95 27 -49 73 -136 104 -194 31 -58 95 -179 143
-270 48 -91 114 -217 147 -280 34 -63 68 -128 78 -145 10 -16 48 -88 85 -160
67 -128 105 -201 170 -320 18 -33 58 -109 90 -170 75 -142 133 -252 180 -340
44 -82 165 -311 180 -340 10 -19 22 -20 273 -23 l262 -2 0 1270 0 1270 100 0
100 0 0 35 0 35 -252 -2 -253 -3 0 -30 0 -30 103 -3 102 -3 0 -894 c0 -492 -2
-895 -4 -895 -4 0 -125 229 -331 625 -37 72 -85 162 -105 200 -20 39 -72 138
-115 220 -138 265 -207 398 -258 495 -27 52 -76 145 -108 205 l-58 110 -515 3
-516 2 0 -35z"/>
<path d="M4000 4880 l0 -40 1400 0 1400 0 0 40 0 40 -1400 0 -1400 0 0 -40z"/>
<path d="M4673 4370 c-126 -19 -245 -106 -300 -217 -26 -54 -28 -66 -28 -178
1 -115 2 -123 32 -181 57 -110 141 -164 358 -230 191 -58 248 -95 266 -171 36
-156 -133 -246 -308 -164 -94 45 -178 135 -272 294 -19 32 -28 37 -58 37 l-35
0 6 -137 c3 -76 9 -177 12 -225 l6 -88 29 0 c22 0 32 7 41 25 14 30 27 31 143
6 242 -53 411 -29 535 76 81 68 133 192 133 318 1 147 -45 242 -152 317 -63
44 -118 66 -293 117 -183 54 -228 91 -228 186 0 82 70 135 177 135 124 -1 218
-80 347 -292 12 -21 24 -28 46 -28 l30 0 0 103 c0 57 -3 145 -6 195 l-7 92
-27 0 c-16 0 -30 -7 -34 -15 -7 -20 -37 -19 -127 4 -90 23 -216 32 -286 21z"/>
<path d="M3540 4148 c-45 -123 -140 -378 -211 -567 -71 -189 -129 -350 -129
-357 0 -8 -17 -14 -47 -16 -48 -3 -48 -3 -48 -38 l0 -35 153 -3 152 -3 0 41 0
40 -55 0 c-30 0 -55 3 -55 8 0 4 24 75 54 157 l53 150 143 3 c79 1 147 -1 152
-6 9 -9 108 -288 108 -303 0 -5 -22 -9 -50 -9 -27 0 -51 -4 -51 -10 -1 -5 -1
-17 -1 -25 0 -47 -17 -45 303 -45 l300 0 -3 38 c-3 36 -4 37 -47 42 l-43 5
-127 345 c-70 190 -165 450 -211 578 l-85 232 -86 0 -86 0 -83 -222z m80 -364
c28 -81 50 -154 50 -161 0 -10 -28 -13 -116 -13 -107 0 -116 1 -109 18 6 14
91 248 110 301 3 8 8 12 10 9 3 -3 28 -72 55 -154z"/>
<path d="M2050 4321 l0 -40 48 -3 47 -3 3 -510 c1 -280 0 -520 -3 -532 -5 -20
-12 -23 -50 -23 l-45 0 0 -40 0 -40 140 0 140 0 0 40 0 40 -45 0 -45 0 0 460
c0 253 2 460 4 460 4 0 40 -66 208 -385 50 -93 109 -206 133 -250 23 -44 66
-125 95 -180 29 -55 63 -118 76 -140 l22 -40 139 -3 138 -3 -2 548 c-1 302 1
560 3 576 5 25 9 27 50 27 l45 0 -3 38 -3 37 -137 3 -138 3 0 -41 0 -40 45 0
45 0 0 -342 0 -343 -20 30 c-11 17 -20 33 -20 36 0 3 -28 59 -63 125 -35 65
-109 208 -165 316 -56 108 -110 210 -120 227 l-17 31 -252 0 -253 0 0 -39z"/>
<path d="M5292 4318 l3 -43 45 -1 45 -1 0 -529 0 -529 -45 0 -45 0 -3 -42 -3
-43 490 0 491 0 0 68 c0 37 3 131 7 210 l6 142 -37 0 c-30 0 -38 -5 -48 -27
-77 -190 -177 -281 -330 -302 -102 -14 -93 -37 -96 242 l-2 247 25 0 c36 0 94
-26 117 -52 22 -26 53 -94 68 -150 10 -36 13 -39 43 -36 l32 3 3 273 2 272
-33 0 c-32 0 -34 -3 -50 -54 -32 -102 -84 -155 -161 -163 l-46 -6 0 238 0 238
83 -5 c136 -7 194 -53 275 -215 35 -70 38 -73 72 -73 l35 0 -3 112 c-2 62 -7
147 -10 188 l-7 75 -463 3 -463 2 3 -42z"/>
<path d="M6352 4318 l3 -43 48 -3 47 -3 -2 -527 -3 -527 -47 -3 -48 -3 0 -40
0 -39 489 0 488 0 7 200 c4 110 5 204 1 210 -3 5 -20 10 -36 10 -25 0 -32 -6
-45 -37 -73 -180 -177 -272 -327 -292 -102 -13 -92 -39 -95 242 l-2 247 25 0
c34 0 95 -26 112 -48 24 -30 60 -106 68 -142 9 -40 19 -50 58 -50 l27 0 0 275
0 275 -35 0 c-30 0 -36 -4 -41 -27 -13 -55 -58 -135 -91 -160 -19 -15 -51 -28
-78 -31 l-45 -5 0 237 0 236 58 0 c140 0 216 -52 292 -203 43 -82 47 -87 78
-87 l32 0 0 68 c0 38 -3 124 -7 190 l-6 122 -464 0 -464 0 3 -42z"/>
<path d="M7420 4320 l0 -40 45 0 45 0 0 -535 0 -535 -45 0 -45 0 0 -40 0 -41
138 3 137 3 3 38 3 37 -49 0 -50 0 -7 475 c-4 262 -5 478 -3 480 3 3 17 -43
31 -103 15 -59 56 -219 92 -357 36 -137 84 -323 106 -413 l42 -163 125 3 125
3 38 175 c21 96 49 227 63 290 96 436 116 530 116 546 0 9 3 15 6 11 7 -7 7
-168 -2 -614 l-7 -333 -44 0 -43 0 0 -40 0 -40 280 0 280 0 0 40 0 40 -45 0
-45 0 0 535 0 535 45 0 45 0 0 40 0 40 -247 -2 -248 -3 -27 -110 c-14 -60 -55
-233 -89 -382 -35 -150 -66 -273 -69 -273 -3 0 -18 48 -33 108 -15 59 -47 184
-72 277 -25 94 -57 218 -72 278 l-27 107 -248 0 -248 0 0 -40z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
